<template>
  <Header />
  <div class="bg-white text-left p-3 border-b-4 border-gray-200">
    <router-link class="font-bold" :to="{name: 'Infographics'}">Back</router-link>
  </div>
  <div class="info-area bg-white h-full w-full flex">

      <div class="">
        <img class="" :src="info_data.uri" :alt="info_data.title" />
      </div>

      <div class="text-center">
        <h1 class="text-3xl md:text-5xl font-bold m-3">{{info_data.title}}</h1>

        <button class="flex items-center justify-center mx-auto border-2 rounded-lg border-indigo-500 text-indigo-500 py-2 px-4" @click="copyProfileLink()">
          Share <img src="@/assets/icons/infographics/share.svg" class="w-4"/>
        </button>

        <p class="w-10/12 mx-auto">{{info_data.description}}</p>
      </div>
    </div>
  <Footer />
</template>
<script>
import axios from 'axios'
// import {rtdb} from '@/_firebase/init.js'
import info_data_json from '@/assets/json/infographic-data.json'
import Header from '@/components/layouts/sections/Header.vue'
import Footer from '@/components/layouts/sections/Footer.vue'


export default {
  components:{
    Header,
    Footer
  },
  data(){
    return{
      info_id: this.$route.params.id,
      all_infos: info_data_json,
      info_data: {}
    }
  },
  methods:{
    getInfographic(){
      axios.get('https://api.skylineexecutive.com/infographics/slug/'+this.info_id).then((res)=>{
        this.info_data = res.data.result
      }).catch((err)=>{
        console.log(err)
      })
    },
    gotoLink(info_id){
      console.log(info_id)
      this.$router.push({name: 'Infographic', params:{id: this.info_id}})
    },
    copyProfileLink(){
      const link = `https://tycooperaow.com/infographics/${this.info_id}`
      navigator.clipboard.writeText(link);
      if(navigator.canShare){
        navigator.share({
          url: link,
          title:'tycooperaow',
          text: ``
        })
      }
    },
  },
  created(){
    this.getInfographic()
  },
}
</script>
<style scoped>
.info-area{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  justify-items: stretch;
  align-items: stretch;
}

@media only screen and (min-width: 600px) {
  .info-area{
    grid-template-columns:1fr 1fr;
    grid-template-rows: 1fr;
  }
}
</style>
